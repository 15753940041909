<template>
    <div>
      <!-- Hiển thị tin nhắn -->
      <div v-for="(message, index) in messages" :key="index" :class="message.role">
        <strong>{{ message.role === 'user' ? 'Bạn:' : 'Chatbot:' }}</strong> {{ message.content }}
      </div>
  
      <!-- Ô nhập tin nhắn -->
      <input
        v-model="userInput"
        @keyup.enter="sendMessage"
        placeholder="Nhập tin nhắn của bạn..."
      />
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        userInput: '', // Tin nhắn người dùng nhập
        messages: [], // Lưu trữ lịch sử tin nhắn
      };
    },
    methods: {
        async sendMessage() {
  if (this.userInput.trim() === '') {
    console.error('Người dùng chưa nhập nội dung.');
    return;
  }

  this.messages.push({ role: 'user', content: this.userInput });
  this.userInput = '';

  if (!this.messages.find(msg => msg.role === 'system')) {
    this.messages.unshift({ role: 'system', content: 'Tôi là một trợ lý AI sẵn sàng hỗ trợ người dùng.' });
  }

  console.log('Dữ liệu gửi lên API:', JSON.stringify(this.messages, null, 2));

  try {
    const response = await axios.post(
      'https://openrouter.ai/api/v1/chat/completions',
      {
        model: 'openai/gpt-4o', 
        messages: this.messages,
        max_tokens: 500, 
        temperature: 0.7,
        stream: false,   
      },
      {
        headers: {
          'Authorization': `Bearer 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`,
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Dữ liệu trả về từ API:', response.data);

    const botMessage = response.data.choices?.[0]?.message?.content || 'Xin lỗi, tôi không nhận được phản hồi hợp lệ.';
    this.messages.push({ role: 'assistant', content: botMessage });
  } catch (error) {
    console.error('Lỗi khi gọi API:', error);
    console.error('Chi tiết lỗi API:', error.response?.data); // 🔍 Log lỗi chi tiết

    let errorMessage = 'Xin lỗi, đã có lỗi xảy ra.';
    if (error.response) {
      errorMessage = `Lỗi ${error.response.status}: ${error.response.data?.message || 'Lỗi không xác định'}`;
    } else if (error.request) {
      errorMessage = 'Không nhận được phản hồi từ máy chủ.';
    }

    this.messages.push({ role: 'assistant', content: errorMessage });
  }
}


    },
  };
  </script>
  
  <style scoped>
  .user {
    color: blue;
  }
  .assistant {
    color: green;
  }
  input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
  }
  </style>